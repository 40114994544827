import { useEffect } from 'react';

const LazyLoader = ({ delay = 150 }) => {
  useEffect(() => {
    const element = document.getElementById('page-loader');

    // Delay when we show loader to prevent "flashing" effect.
    let timeout = setTimeout(() => {
      timeout = null;
      element.style.display = 'flex';
    }, delay);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      // Delay hiding loader slightly to prevent "flashing" effect.
      setTimeout(() => {
        element.style.display = 'none';
      }, 250);
    };
  }, [delay]);

  return null;
};

export { LazyLoader as default };

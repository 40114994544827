import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  periodStart: new Date().getTime(),
  periodEnd: new Date().getTime(),
  timePeriod: 'week',
  timePeriodOffset: 0,
  currentTimeLog: null,
};

const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    setPeriodStart(state, action) {
      state.periodStart = typeof action.payload !== 'number' ? new Date().getTime() : action.payload;
    },
    setPeriodEnd(state, action) {
      state.periodEnd = typeof action.payload !== 'number' ? new Date().getTime() : action.payload;
    },
    setTimePeriod(state, action) {
      state.timePeriod = action.payload === null ? 'week' : action.payload;
      state.timePeriodOffset = 0;
    },
    setTimePeriodOffset(state, action) {
      state.timePeriodOffset = action.payload === null ? 0 : action.payload;
    },
    setCurrentTimeLog(state, action) {
      state.currentTimeLog = action.payload === null ? null : { ...action.payload };
    },
  },
});

export const { setPeriodStart, setPeriodEnd, setTimePeriod, setTimePeriodOffset, setCurrentTimeLog } = timeSlice.actions;
const timeReducer = timeSlice.reducer;

export default timeReducer;

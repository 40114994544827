import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  enableNotifications: 'Notification' in window && Notification.permission === 'granted',
  showTimerNotification: true,
  showTimerNotificationActions: true,
};

const authSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setEnableNotifications(state, action) {
      state.enableNotifications = !!action.payload;
    },
    setShowTimerNotification(state, action) {
      state.showTimerNotification = !!action.payload;
    },
    setShowTimerNotificationActions(state, action) {
      state.showTimerNotificationActions = !!action.payload;
    },
  },
});

export const { setEnableNotifications, setShowTimerNotification, setShowTimerNotificationActions } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;

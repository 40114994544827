// cra imports
import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from 'functions/helpers/reportWebVitals';
import * as serviceWorkerRegistration from 'functions/helpers/serviceWorkerRegistration';

// import redux requirements
import { Provider } from 'react-redux';
import { store } from 'state';

// import routing modules
import { BrowserRouter as Router } from 'react-router-dom';

import LazyLoader from 'layout/LazyLoader';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: () => Promise.resolve(),
});

const App = lazy(() => import('App'));

const Main = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<LazyLoader />}>
        <Router basename={process.env.REACT_APP_BASENAME}>
          <App />
        </Router>
      </Suspense>
    </Provider>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(<Main />);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLogin: false,
  currentUser: {},
  timer: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      // set convenience properties for easy role checking
      if (action.payload && action.payload.has_roles) {
        action.payload.has_roles.forEach((role) => {
          action.payload[`can_${role.replace(/ /g, '_')}`] = true;
        });
      }
      // set convenience properties for easy connection checking
      if (action.payload && action.payload.has_connections) {
        action.payload.has_connections.forEach((connection) => {
          action.payload[`has_${connection}`] = true;
        });
      }
      state.currentUser = action.payload === null ? null : { ...action.payload };
      state.isLogin = action.payload !== null;
      state.timer = action.payload === null ? null : action.payload.timer;
    },
    setTimer(state, action) {
      state.timer = action.payload === null ? null : { ...action.payload };
    },
  },
});

export const { setCurrentUser, setTimer } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;

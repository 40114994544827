// import redux and persist plugins
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';

// import theme reducers
import settingsReducer from 'state/settingsSlice';
import layoutReducer from 'state/layoutSlice';
import langReducer from 'state/langSlice';
import authReducer from 'state/authSlice';
import notificationsReducer from 'state/notificationsSlice';
import menuReducer from 'state/menuSlice';
import timeReducer from 'state/timeSlice';
import paymentReducer from 'state/paymentSlice';

// import persist key
import { REDUX_PERSIST_KEY } from 'constants.js';

const persistConfig = {
  version: 1,
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['menu', 'settings', 'lang', 'auth', 'layout', 'notifications', 'payment', 'time'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    settings: settingsReducer,
    layout: layoutReducer,
    lang: langReducer,
    auth: authReducer,
    notifications: notificationsReducer,
    menu: menuReducer,
    time: timeReducer,
    payment: paymentReducer,
  })
);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };

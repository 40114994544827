import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  expandedTimer: true,
  showingSearch: false,
  showingNavMenu: '',
  pwaPrompted: false,
  tourDismissed: false,
  lineGraphPeriod: 'day',
  logTimePicker: 'task',
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    layoutShowingNavMenu(state, action) {
      state.showingNavMenu = action.payload;
    },
    layoutExpandedTimer(state, action) {
      state.expandedTimer = !!action.payload;
    },
    layoutShowingSearch(state, action) {
      state.showingSearch = !!action.payload;
    },
    setPwaPrompted(state, action) {
      state.pwaPrompted = !!action.payload;
    },
    setTourDismissed(state, action) {
      state.tourDismissed = !!action.payload;
    },
    setLineGraphPeriod(state, action) {
      state.lineGraphPeriod = action.payload;
    },
    setLogTimePicker(state, action) {
      state.logTimePicker = action.payload;
    },
  },
});
export const { layoutShowingNavMenu, layoutExpandedTimer, layoutShowingSearch, setPwaPrompted, setTourDismissed, setLineGraphPeriod, setLogTimePicker } =
  layoutSlice.actions;
const layoutReducer = layoutSlice.reducer;

export default layoutReducer;

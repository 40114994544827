import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invoicePayment: null,
  partialPayment: null,
};

const authSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setInvoicePayment(state, action) {
      state.invoicePayment = action.payload === null ? null : { ...action.payload };
      if (action.payload === null) {
        state.partialPayment = null;
      }
    },
    setPartialPayment(state, action) {
      state.partialPayment = action.payload;
    },
  },
});

export const { setInvoicePayment, setPartialPayment } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;

export const DIMENSION = {
  Desktop: 'desktop',
  Tablet: 'tablet',
  Mobile: 'mobile',
};

export const LAYOUT = {
  Fluid: 'fluid',
  Boxed: 'boxed',
};

export const RADIUS = {
  Rounded: 'rounded',
  Standard: 'standard',
  Flat: 'flat',
};

export const THEME_COLOR = {
  LightBlue: 'light-blue',
  DarkBlue: 'dark-blue',
  LightRed: 'light-red',
  DarkRed: 'dark-red',
  LightGreen: 'light-green',
  DarkGreen: 'dark-green',
  LightPurple: 'light-purple',
  DarkPurple: 'dark-purple',
  LightPink: 'light-pink',
  DarkPink: 'dark-pink',
};

export const NAV_COLOR = {
  Default: 'default',
  Light: 'light',
  Dark: 'dark',
};
export const MENU_PLACEMENT = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};
export const MENU_BEHAVIOUR = {
  Pinned: 'pinned',
  Unpinned: 'unpinned',
};

export const DEFAULT_MONOGRAM_COLOR = '#4f5863';

// Monogram symbols
export const MONOGRAM_SYMBOLS = {
  '!1': '\uF28A', // circle
  '!2': '\uF288', // circle half
  '!3': '\uF287', // circle fill
  '!4': '\uF2F1', // diamond
  '!5': '\uF2F0', // diamond half
  '!6': '\uF2EF', // diamond fill
  '!7': '\uF41A', // heptagon
  '!8': '\uF419', // heptagon half
  '!9': '\uF418', // heptagon fill
  '!0': '\uF41D', // hexagon
  '!A': '\uF41C', // hexagon half
  '!B': '\uF41B', // hexagon fill
  '!C': '\uF4AC', // octagon
  '!D': '\uF4AB', // octagon half
  '!E': '\uF4AA', // octagon fill
  '!F': '\uF4CE', // pentagon
  '!G': '\uF4CD', // pentagon half
  '!H': '\uF4CC', // pentagon fill
  '!I': '\uF584', // square
  '!J': '\uF583', // square half
  '!K': '\uF582', // square fill
  '!L': '\uF5E5', // triangle
  '!M': '\uF5E4', // triangle half
  '!N': '\uF5E3', // triangle fill
  '!O': '\uF588', // star
  '!P': '\uF587', // star half
  '!Q': '\uF586', // star fill
  '!R': '\uF417', // heart
  '!S': '\uF416', // heart half
  '!T': '\uF415', // heart fill
  '!U': '\uF3E6', // gem
  '!V': '\uF1D2', // sun
  '!W': '\uF2C1', // cloud
  '!X': '\uF423', // house
  '!Y': '\uF497', // moon
  '!a': '\uF5E2', // tree
  '!b': '\uF59A', // club (suit)
  '!c': '\uF5A0', // spade (suit)
};

export const INVOICE_THEMES = [
  {
    value: 'red-sunset',
    color: 'invoice-red',
    label: 'Warm Sunset',
  },
  {
    value: 'orange-leaves',
    color: 'invoice-orange',
    label: 'Fall Leaves',
  },
  {
    value: 'yellow-desert',
    color: 'invoice-yellow',
    label: 'Scorching Desert',
  },
  {
    value: 'lime-limes',
    color: 'invoice-lime',
    label: 'Fresh Limes',
  },
  {
    value: 'green-clovers',
    color: 'invoice-green',
    label: 'Morning Clovers',
  },
  {
    value: 'cyan-ocean',
    color: 'invoice-cyan',
    label: 'Crystal Ocean',
  },
  {
    value: 'azure-bubbles',
    color: 'invoice-azure',
    label: 'Blue Bubbles',
  },
  {
    value: 'blue-mountains',
    color: 'invoice-blue',
    label: 'Foggy Mountains',
  },
  {
    value: 'violet-leaf',
    color: 'invoice-violet',
    label: 'Dewey Leaf',
  },
  {
    value: 'magenta-flowers',
    color: 'invoice-magenta',
    label: 'Bright Bloom',
  },
  {
    value: 'pink-magnolias',
    color: 'invoice-pink',
    label: 'Pristine Spring',
  },
  {
    value: 'black-and-white-studio',
    color: 'invoice-gray',
    label: 'Photography Studio',
  },
  {
    value: 'brown-and-green-food',
    color: 'invoice-deep-green',
    label: 'Tasty Food',
  },
  {
    value: 'brown-and-red-tools',
    color: 'invoice-brown',
    label: 'Repair Tools',
  },
  {
    value: 'dark-blue-code',
    color: 'invoice-azure',
    label: 'Late Night Coding',
  },
  {
    value: 'gold-and-white-wedding',
    color: 'invoice-gold',
    label: 'Golden Wedding',
  },
  {
    value: 'gray-pebbles',
    color: 'invoice-light-gray',
    label: 'Calming Pebbles',
  },
  {
    value: 'green-and-pink-lawn',
    color: 'invoice-green',
    label: 'Clean Cut Lawn',
  },
  {
    value: 'green-tree',
    color: 'invoice-deep-green',
    label: 'Serene Tree',
  },
  {
    value: 'multicolored-city',
    color: 'invoice-violet',
    label: 'City Life',
  },
  {
    value: 'tan-and-orange-tools',
    color: 'invoice-gold',
    label: 'Construction Tools',
  },
  {
    value: 'white-and-gray-clouds',
    color: 'invoice-gray',
    label: 'Glowing Clouds',
  },
  {
    value: 'white-and-tan-construction',
    color: 'invoice-light-gray',
    label: 'Construction Plans',
  },
];

// For detailed information: https://github.com/nfl/react-helmet#reference-guide
export const REACT_HELMET_PROPS = {
  defaultTitle: 'TimeStash - Track it and stash it!',
  titleTemplate: '%s | TimeStash',
};

// Redux key
export const REDUX_PERSIST_KEY = 'time-stash';

// Used for redirects and routing
export const DEFAULT_PATHS = {
  APP: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token/:email',
  UNAUTHORIZED: '/unauthorized',
};

// Theme and layout defaults
export const DEFAULT_SETTINGS = {
  MENU_PLACEMENT: MENU_PLACEMENT.Vertical,
  MENU_BEHAVIOUR: MENU_BEHAVIOUR.Pinned,
  LAYOUT: LAYOUT.Fluid,
  RADIUS: RADIUS.Rounded,
  COLOR: THEME_COLOR.LightBlue,
  NAV_COLOR: NAV_COLOR.Default,
};
